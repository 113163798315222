import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import Abtdiv from './Abtdiv';
import Home from './Home';
import Contact from './Contact';
import Check from './Check';
import Login from './Login';
import Undercon from './Undercon';
import Jobhome from './Jobhome';
import Onlinetest from './Onlinetest';
import Configdata from './Defaultdata';
class Header extends Component {
state = {
    abt: 1,cnt:2
  }; 
 handleAgeChange = () => {
	
	 const element = <Jobhome />
	 ReactDOM.render(element, document.getElementById('container'));
 };
	handleChangeonlinetest = () => {
		const element = <Onlinetest />

		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangecontact = () => {
		const element = <Contact />
		ReactDOM.render(element, document.getElementById('container'));
	};
	handleChangehome = () => {
		const element = <Home />;

		ReactDOM.render(element, document.getElementById('container'));

		
	};
	handleChangelogin = () => {
		const element = <Login />
		ReactDOM.render(element, document.getElementById('container'));
	};
	render() {

		
  return (
  <div>
	<div className="topdiv">

	 {/*    <div className="userprofile">*/}
		{/*<a href="#">*/}
		{/*<span className="floatright">Profile List</span>*/}
		{/*</a>*/}
	 {/*  </div>*/}
	 {/* <div className="userprofile">*/}
		{/*<a href="#">*/}
		{/*<span className="floatright">Profile</span>*/}
		{/*</a>*/}
	 {/* </div>*/}

	 <div className="userprofile">
		<a  href="#">
		<span className="floatright">Sign Up</span>
		</a>
	 </div>

	<div className="userprofile">
				  <a onClick={this.handleChangelogin} href="#">
		<span className="floatright"> SignIn </span>
		</a>
	</div>
	
  </div>
		  <div className="topdiv">
			  <div className="userprofile">
				  <a href="#">
					  <span id="welcome" className="floatright">  </span>
				  </a>
			  </div>
		  </div>
	<div className="submenudiv">
		<span className="topmenu">
			<p>
					  <a onClick={this.handleChangehome} href="#">Home</a>
			</p>
		</span>
		<span className="topmenu">
			<p>
				<a onClick={this.handleAgeChange} href="#">About US</a>
			</p>
		</span>
		<span className="topmenu">
			<p>
					  <a onClick={this.handleChangeonlinetest} href="#">Test</a>
			</p>
		</span>
		<span className="topmenu">
			<p>
					  <a onClick={this.handleAgeChange} href="#">Adv&#128269;</a>
			</p>
		</span>
		<span className="topmenu">
			<p>
					  <a onClick={this.handleChangecontact} href="#">Contact</a>
			</p>
		</span>
	</div>


</div>

      );
}
}
export default Header;
