import React,{ Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './mainapp.css';
import Select from 'react-select';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';

import * as ReactDOM from 'react-dom';
function App() {


  return (
  <div>
	 
	<Header />
		  <div id="container" className="containtdiv">
			  <Home />
	
	</div>
	<Footer />

</div>

      );
}

export default App;
